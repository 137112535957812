/* General */

.ant-layout {
  background: var(--dark-dark);
}

/* Nav drawer */

.ant-menu.ant-menu-dark {
  font-size: 16px;
  background: var(--dark-dark);
}

.ant-layout-sider {
  border-right: var(--border);
  background: var(--dark-dark);
}

.ant-layout-sider-trigger {
  background: var(--dark-default);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: linear-gradient(
    270deg,
    rgba(48, 167, 239, 0.03) -1.19%,
    rgba(48, 167, 239, 0) 70%
  );
  border-right: 2px solid var(--blue);
}

.ant-menu-dark .ant-menu-item:hover {
  color: var(--blue);
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected {
  color: var(--blue);
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  font-weight: 500;
  color: var(--blue);
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 20px;
}

.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 41px;
}

/* MobileDrawer */
.ant-drawer-content {
  background-color: rgba(7, 24, 39, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
}

.ant-drawer-header {
  height: 68px;
  background: transparent;
  border-bottom: none;
}

.ant-drawer-body {
  text-align: right;
  color: white;
  padding-left: 0;
  padding-top: 0;
}

.ant-drawer-item {
  transition: all 0.2s ease-in-out;
}

.ant-drawer-item:hover {
  color: var(--faded-white);
}

.ant-drawer-title {
  color: var(--dark-default);
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(255, 255, 255, 0.5);
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  width: 100vw !important;
}

/* Carousel Arrows */

.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next,
.slick-slider:hover .slick-dots {
  opacity: 1;
}

.ant-carousel .slick-dots {
  transition: all 0.2s ease-in-out;
  opacity: 1;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  transition: all 0.2s ease-in-out;
  display: block;
  height: 48px;
  width: 48px;
  padding: 12px;
  border-radius: 100px;
  z-index: 2;
}

.ant-carousel .slick-prev {
  margin-left: 34px;
}

.ant-carousel .slick-next {
  margin-right: 34px;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  opacity: 0;
  background-color: rgba(18, 18, 24, 0);
  color: rgba(255, 255, 255, 0.85);
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  color: white;
  background-color: rgba(18, 18, 24, 0.54);
}

.ant-carousel .slick-list .slick-slide {
  pointer-events: auto;
}

.ant-popover-inner {
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #122940;
}

.ant-popover-inner-content {
  padding: 0px;
}

.ant-popover-arrow-content {
  background-color: #122940;
}

.ant-modal-content {
  background-color: #122940;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

.ant-modal-footer {
  border: none;
}

.ant-form-item-label > label {
  color: var(--faded-white);
  font-weight: bold;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--dark-default);
  border: none;
  color: white;
  padding: 15px 20px;
  height: auto;
}

.ant-select-item {
  color: var(--faded-white);
  padding: 10px 15px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--dark-light);
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--dark-light);
  color: white;
}

.ant-select-arrow {
  color: var(--faded-white);
}

.ant-progress-circle .ant-progress-text {
  color: white;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: var(--blue);
}
